<template lang="pug">
app-layout
  .about-page
    .about-page__intro-block.intro-block
      h1.intro-block-title.t-h1-mobile.mb-8 Маркетплейс бизнес&#8209;активов и&nbsp;залогового&nbsp;имущества
      .intro-block-stats
        .intro-block-stats__item
          .intro-stats__item__value.t-h1-mobile {{ aboutInfo.assets }}
          .intro-block-stats__item__label.t-caption-small активов
        .intro-block-stats__item
          .intro-stats__item__value.t-h1-mobile {{ aboutInfo.investors }}
          .intro-block-stats__item__label.t-caption-small зарегистрированных инвестора
        .intro-block-stats__item
          .intro-block-stats__item__value.t-h1-mobile {{ aboutInfo.sales }} млрд ₽ +
          .intro-block-stats__item__label.t-caption-small реализовано

    .about-page__description-block.description-block
      .description-block__title.t-h2-mobile
        | Portal DA — сервис от Сбербанка, предназначенный для покупки и продажи залогового имущества и непрофильных активов
      .description-block__text.t-h4-mobile
        | На Portal DA представлены активы с различными доступными способами покупки — и активы, продаваемые собственником по прямому договору купли-продажи, и активы в залоге, реализуемые на торгах в процедурах банкротства / исполнительного производства.
        |
        | Мы предлагаем воспользоваться нашим сервисом всем желающим купить или продать реальные крупные активы — от коммерческой недвижимости и производственных предприятий до частных домов и квартир.
      .description-block__text--normal.t-normal
        | Перед банками и организациями, работающими в разных отраслях, рынок часто ставит задачу эффективно реализовать залоговое имущество, принятое в качестве обеспечения исполнения кредитных обязательств, а также непрофильные активы. Это значит, что продавцам необходимо максимально быстро и выгодно реализовать очень разнородные объекты – от автомобилей и квартир до оборудования, заводов и готового бизнеса. От инвесторов приобретение подобных активов требует внимания к деталям, но позволяет значительно сэкономить – купить залоговое имущество ниже его рыночной стоимости.
        | Мы сосредоточены на том, чтобы сделать этот процесс максимально прозрачным, открытым и понятным для всех заинтересованных сторон. Для наиболее эффективного поиска залогового движимого и недвижимого имущества и непрофильных активов на нашей площадке представлена обширная база (реестр) активов, реализуемых банками (в т.ч. Сбербанком), отраслевыми компаниями, индивидуальными предпринимателями, арбитражными управляющими.

    .about-page__features-block
      .features-block__title.t-h2-mobile.mb-4
        | Универсальная платформа для продажи активов и поиска инвесторов
      .features-block__text--normal.t-normal
        | Поиск находящихся в реализации крупных реальных активов является сложным и трудоемким, поэтому Сбербанк создал единый сайт для продажи непрофильных активов и активов, находящихся в залоге у банков.
      .features-block__subtitle.t-h3-mobile.mt-8.mb-6
        | Наши преимущества
      v-window.features-block__slider(v-model="advantagesSlide" continuous)
        v-window-item
          info-card(min-height=320 title="Официальный ресурс")
            template(#image)
              img.ml-n2.mb-n4(src="~@/assets/96/files.svg")
            | Информация размещается напрямую продавцами и является достоверной
        v-window-item
          info-card(min-height=320 title="Целевая площадка для крупных реальных активов")
            template(#image)
              img.ml-n2.mb-n4(src="~@/assets/96/users.svg")
            | Ваше предложение доступно сразу широкому кругу профильных потенциальных покупателей, готовых покупать крупные реальные активы, в т.ч. находящиеся в залоге.
        v-window-item
          info-card(min-height=320 title="Все виды активов для бизнеса")
            template(#image)
              img.ml-n2.mb-n4(src="~@/assets/96/assets.svg")
            | На площадке представлены все виды бизнес-активов (торговая недвижимость, офисы, склады, гостиницы, производственные предприятия, сельскохозяйственные комплексы, оборудование, спецтехника и др)
        v-window-item
          info-card(min-height=320 title="Различные способы покупки")
            template(#image)
              img.ml-n2.mb-n4(src="~@/assets/96/sell.svg")
            | Доступны различные способы покупки – от прямой покупки от собственника до покупки на торгах в процедуре банкротства
      slider-dots.mt-4(v-model="advantagesSlide" :total="4")

    .about-page__seller-block.seller-block
      .seller-block__title.t-h1-mobile.mb-4 Для продавца
      .seller-block__subtitle.t-h4-mobile.mb-10 Для продажи имущества Вы можете закупить услуги агента (при этом размещение активов бесплатное) или разместить объявление о продаже/аренде самостоятельно на платной основе.

      .seller-block__agent-service.agent-service
        .agent-service__title.t-h2-mobile.mb-4 Привлечение агента для реализации имущества
        .t-normal.mb-4 Агент возьмет на себя весь процесс от размещения актива на Portal DA до передачи вам реальных интересантов на покупку имущества.&nbsp;
          span.c-primary(@click="onDesktopClick") Стоимость агентских услуг
        p.agent-service__advantage.t-normal Размещение актива бесплатно
        p.agent-service__advantage.t-normal Оплата комиссии только за фактическую сделку с приведенным нами инвестором
        p.agent-service__advantage.t-normal Комиссия 1,2–2,4 % в зависимости от типа и сложности актива
        p.agent-service__advantage.t-normal Многофункциональная команда с реальным опытом привлечения инвесторов и реализации сложных сделок
        p.agent-service__advantage.t-normal Возможность автозагрузки пула активов (XML фид)

        .agent-service__divider.mt-2

        .agent-service__title.t-h2-mobile.mb-4 Платное размещение
        .t-normal.mb-4 Объявление в разделы "продам"/"аренда" размещаются самостоятельно в рамках &nbsp;
          span.c-primary(@click="onDesktopClick") действующих тарифов.
        p.agent-service__advantage.t-normal Простой процесс размещения
        p.agent-service__advantage.t-normal Возможность автозагрузки пула активов (XML фид)
        p.agent-service__advantage.t-normal Удобный чат с покупателями
        p.agent-service__info.t-small.mt-6 Для арбитражных управляющих, физических лиц и клиентов решения «Недвижимость» размещение объявлений о продаже имущества бесплатно, для юридических лиц предоставлена возможность размещения бесплатно не более 2-х объявлений
        ui-btn(
          large
          primary
          @click="openGoWizardDialog"
          width="100%"
        ) Разместить актив

    .about-page__mobile-app-block.mobile-app-block
      .mobile-app-block__title.t-h1-mobile Мобильное приложение
      p.mobile-app-block__text.t-normal Ищите и приобретайте активы в любом месте и в любое время. Приложения для iPhone, iPad и Android позволяют одним касанием найти все активы, добавить их в «Избранное», связаться с продавцом
      .mobile-app-block__mobile.mobile
        img.mobile-image(src="~@/assets/mobile-app-on-phone.png")
        .mobile-apps
          a.mobile-apps__ios(:href="$path.getAppApple()" target="_blank")
          a.mobile-apps__android(:href="$path.getAppRustore()" target="_blank")

    .about-page-awards
      .about-page-awards__title.t-h1-mobile.mb-5
        | Наши награды
      v-window.about-page-awards__slider(v-model="awardsSlide")
        v-window-item
          .about-page-award-card
            .about-page-award-card__year 2023
            .about-page-award-card__img
              img(
                src="~@/assets/award-cdo-cdto-2023-2x.png"
                alt="CDO/CDTO Awards 2023 Grand Prix"
                width="153"
                height="230"
              )
            h5.about-page-award-card__title CDO/CDTO Summit & Award
            p.about-page-award-card__desc Номинация «Цифровая платформа года»
        v-window-item
          .about-page-award-card
            .about-page-award-card__year 2022
            .about-page-award-card__img
              img(
                src="~@/assets/award-digital-leaders-2022-2x.png"
                alt="Лауреат премии Digital Leaders 2022"
                width="111"
                height="212"
              )
            h5.about-page-award-card__title Digital Leaders Award
            p.about-page-award-card__desc Номинация «Трансформация года»
        v-window-item
          .about-page-award-card
            .about-page-award-card__year 2022
            .about-page-award-card__img
              img(
                src="~@/assets/award-adesign-2022-2x.png"
                alt="A'Design Award & Competition 2022"
                width="106"
                height="161"
              )
            h5.about-page-award-card__title A'Design Award and Competition
            p.about-page-award-card__desc Бронза, дизайн мобильного приложения Portal DA
        v-window-item
          .about-page-award-card
            .about-page-award-card__year 2021
            .about-page-award-card__img
              img(
                src="~@/assets/award-master-2021-2x.png"
                alt="Мастер Разрешения Долговых Споров 2021"
                width="150"
                height="170"
              )
            h5.about-page-award-card__title Премия «Мастер разрешения долговых споров»
      slider-dots.mt-4(v-model="awardsSlide" :total="4")
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import InfoCard from '@/components/pages/InfoCard.vue';
  import { UiBtn, UiBadge } from '@/uikit';
  import { service as API } from '@/services/pages';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageAbout', 'blue');

  import SliderDots from '@/components/SliderDots';

  export default {
    name: 'about',
    components: { AppLayout, UiBtn, UiBadge, InfoCard, SliderDots },
    inject: ['openDesktop', 'openGoWizardDialog'],

    data: () => ({
      awardsSlide: 0,
      advantagesSlide: 0,
      partnersSlide: 0,
      aboutInfo: {
        assets: null,
        investors: null,
        sales: null,
      },
    }),

    mounted() {
      this.fetchAbout();
    },

    methods: {
      async fetchAbout() {
        try {
          this.loading = true;
          this.aboutInfo = await API.about();
        } catch (error) {
          logger.error(error);
        } finally {
          this.loading = false;
        }
      },

      onDesktopClick() {
        this.openDesktop({ path: '/about' });
      },
    },
  };
</script>

<style lang="scss">
  .about-page {
    &__intro-block {
      color: white;
      padding: 56px 30px;
      background: url('~@/assets/about-page-underlay1-bg.jpg') no-repeat;
      background-size: cover;
    }
    .intro-block-title {
      text-align: center;
    }
    .intro-block-stats {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__label {
          text-align: center;
        }
      }
    }
    &__description-block {
      display: flex;
      flex-direction: column;
      padding: 56px 16px;
      background: white;
      gap: 32px;
    }
    .description-block {
      &__title {
        color: #25282b;
      }
      &__text {
        color: #52575c;
      }
      &__text--normal {
        color: #52575c;
      }
    }
    &__features-block {
      padding: 48px 16px;
    }
    &__seller-block {
      padding: 56px 16px;
      background: url('~@/assets/about-page-underlay2-bg.jpg');
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .seller-block {
      &__title {
        color: white;
      }
      &__subtitle {
        color: white;
      }
      &__agent-service {
        text-align: start;
        width: 100%;
        padding: 40px 24px;
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        &::before {
          content: '';
          display: block;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        .agent-service {
          &__advantage {
            color: #52575c;
            text-align: start;
            display: flex;
            &::before {
              content: '';
              display: block;
              flex-shrink: 0;
              width: 16px;
              height: 16px;
              margin-top: 2px;
              margin-right: 8px;
              @include svg-bg-mask('check');
              background-color: #53ad59;
            }
          }
          &__info {
            color: #909497;
          }
          &__divider {
            border-bottom: 1px solid #e8e8e8;
            margin-bottom: 32px;
          }
          &__badge {
            display: inline-block;
            position: relative;
            top: 21px;
            background: #388bf2;
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
            border-radius: 0 0 2px 2px;
          }
        }
      }
    }
    &__mobile-app-block {
      padding: 56px 16px 0 16px;
    }
    .mobile-app-block {
      &__title {
        margin-bottom: 16px;
      }
      &__text {
        color: #52575c;
        margin-bottom: 120px;
      }
      &__mobile {
        height: 532px;

        background: linear-gradient(97.7deg, #25282b 0%, #131516 100%), #25282b;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
      }
      .mobile-image {
        position: relative;
        top: -80px;
        margin: auto;
        height: 484px;
        width: 240px;
      }
      .mobile-apps {
        display: flex;
        padding: 0 42px;
        position: relative;
        justify-content: space-between;
        top: -56px;
        &__ios {
          width: 120px;
          height: 40px;
          @include svg-bg-colored('appstore-white', 'assets');
        }
        &__android {
          @include svg-bg-colored('rustore-logo', 'assets');
          width: 120px;
          height: 40px;
          background-size: 90%;
          border: 1px solid #000;
          border-radius: 6px;
          background-color: #a4a5a6;
        }
      }
    }
    &__our-partners-block {
      padding: 0 16px 32px 16px;
    }
  }

  .about-page-awards {
    position: relative;
    z-index: 0;
    padding: 56px 16px;

    .about-page-award-card {
      border: 1px solid c('gray20', 'light');
      border-radius: 12px;
      padding: 56px 24px 32px 24px;
      position: relative;
      margin-top: 12px;
      height: 450px;
    }

    .about-page-award-card__img {
      height: 230px;
      margin-bottom: 40px;
    }

    .about-page-award-card__year {
      @include text-style('h3', 'light');
      color: c('gray80', 'light');
      position: absolute;
      left: 16px;
      top: -12px;
      background-color: c('gray5', 'light');
      padding-left: 8px;
      padding-right: 8px;
    }

    .about-page-award-card__desc {
      @include text-style('normal', 'light');
      color: c('gray80', 'light');
    }

    .about-page-award-card__title {
      @include text-style('h3-mobile', 'light');
      margin-bottom: 4px;
    }
  }
</style>
